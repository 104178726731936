class ProgressBar {
  constructor(selector) {
    this.$progressBar = $(selector);
    this.documentHeight = $(document).height();
    this.windowHeight = $(window).height();
    this.windowWidth = $(window).width();
  }

  static create(selector) {
    const progressBar = new ProgressBar(selector);
    progressBar.init();
    progressBar.registerEvents();
    return progressBar;
  }

  init() {
    this.setValue();
    this.setValueMax();
  }

  registerEvents() {
    $(document).on('scroll', () => this.setValue());
  }
  
  getValue() {
    return $(window).scrollTop();
  }

  getValueMax() {
    return this.documentHeight - this.windowHeight;
  }
  
  getWidth() {
    return this.windowWidth * this.getValue() / this.getValueMax();
  }

  setValue() {
    this.$progressBar.attr('aria-valuenow', this.getValue());
    this.$progressBar.css('width', `${this.getWidth()}px`);
  }

  setValueMax() {
    this.$progressBar.attr('aria-valuemax', this.getValueMax());
  }
}

$(() => ProgressBar.create('[data-blog-progress-bar]'));